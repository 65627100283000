import React from "react"
import Header from "../../components/profissionaisSaude/Header"
import Footer from "../../components/profissionaisSaude/Footer"

export default function Help() {
  return (
    <>
      <Header />
      <h1>Hello, world!</h1>
      <Footer />
    </>
  )
}
